<template>
  <div>
    <div class="flexs">
      <sidebarUserInfo style="flex: 0 0 300px; height: fit-content" />
      <div style="flex: 1">
        <top-banner class="banners" />
        <div class="top-lists">
          <div
            class="nav-view"
            @click="$router.push(item.url)"
            :class="index == 1 ? 'styles' : ''"
            v-for="(item, index) in navList"
          >
            {{ item.name }}
          </div>
        </div>
        <div style="position: relative">
          <div class="content">
            <div class="title">
              <div class="line"></div>
              <div class="name">目标业绩进度</div>
            </div>
            <div class="box">
                <div class="titles">{{ startDate }} 至 {{endDate}}</div>
                <div class="echarts">
                    <div class="title_s">{{$t('w_0129')}}(PV)</div>
                    <div class="e-chat">
                      <div class="list" v-for="item in list ">
                          <div class="tops">

                          </div>
                          <div class="process" :style="{height:item.percentage}" >
                              <div class="money">{{item.percentage}}</div>
                          </div>
                      </div>
                    </div>
                    <div class="e-chat-s" >
                        <div class="moneys" v-for="item in list ">
                            {{item.targetPerformanceLeaveOut}}
                        </div>
                    </div>
                </div>
                <div class="titles" style="margin-top: 40px">{{ startDate1 }} 至 {{endDate1}}</div>
                <div class="echarts">
                    <div class="title_s" style="margin-top: 250px" >{{$t('w_0129')}}(金额)</div>
                    <div class="e-chat" style="margin-top: 40px">
                        <div class="list" v-for="item in list1 ">
                            <div class="tops">

                            </div>
                            <div class="process" :style="{height:item.percentage}" >
                                <div class="money">{{item.percentage}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="e-chat-s" style="margin-left: 145px">
                        <div class="moneys" v-for="item in list1 ">
                            {{item.targetPerformanceLeaveOut}}
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import * as apis from "@/api/index.js";
import sidebarUserInfo from "@/components/sidebarUserInfo";
import topBanner from "@/components/topBanner";
import * as api from "@/api/register";
import { getToken } from "@/util/auth";
import {getPerFormance,getPerFormanceAmount} from "@/api/register";
export default {
  data() {
    return {
       // list:[{"name":"20万","percent":15,num:20},{"name":"40万","percent":25,num:40},
       //     {"name":"60万","percent":22,num:60},{"name":"80万","percent":20,num:80},
       //     {"name":"100万","percent":10,num:100}
       // ],
      list:[],
      list1:[],
      xData: [20, 40, 60, 80, 100], //横坐标
      yData: [20, 24, 18, 25, 27], //数据
      myChartStyle: { float: "left", width: "100%", height: "400px" },
      tableData: [],
      total: 0,
      queryParams: {
        pageNum: 1,
        pageSize: 50,
        startCreationTime: "",
        endCreationTime: "",
        memberCode: "",
        memberName: "",
      },
      startDate:'',
      endDate:'',
      startDate1:'',
      endDate1:'',
        navList: [
        { name: "认证资质", url: "/superStudio" },
        { name: this.$t('PER_DA_10'), url: "/performance" },
        { name: this.$t('w_0127'), url: "/giftBag" },
      ],
    };
  },
  components: { sidebarUserInfo, topBanner },
  watch: {},
  mounted() {
      this.getPerFormance();
      this.getPerFormanceAmount();
  },
  methods: {
      getPerFormance(){
          getPerFormance().then((res)=>{
              this.startDate = res.data.startDate
              this.endDate = res.data.endDate
              this.list = res.data.targetPerformanceList
          })
      },
      getPerFormanceAmount(){
          getPerFormanceAmount().then((res)=>{
              this.startDate1 = res.data.startDate
              this.endDate1 = res.data.endDate
              this.list1 = res.data.targetPerformanceList
          })
      }
      }
};
</script>
<style>
.tops{
    /*width: 128px;*/
    /*height: 17px;*/
    /*background: #F3F3F3;*/
    /*box-shadow: 0px -2px 6px 0px rgba(0,0,0,0.1);*/
    /*position: absolute;*/
    /*top: 0;*/
    /*margin-top: -17px;*/
}
.process{
    background: #C8161D;
    /*height: 50%;*/
    width: 100%;
    position: absolute;
    justify-content: center;
    align-items:center;
    display: flex;
    bottom: 0;
}
.moneys{
    width: 130px;
    margin-right: 40px;
    text-align: center;
}
.e-chat-s{
    width: 840px;
    position: absolute;
    display: flex;
    bottom: 0;
    justify-content: center;
    margin-left: 135px;

}
.money{
    position: absolute;
    bottom: 5px;
    margin-top: -100px;
    color: #fff;
}
.list{
    position: relative;
    justify-content: center;
    align-items: center;
    display: flex;

}
.e-chat{
    display: flex;
    margin-top: 40px;
}
.list{
    width: 128px;
    height: 184px;
    background: #F3F3F3;
    margin-right: 40px;
    border-radius: 7px 7px 0 0 ;
}
.title_s{
    margin: 260px 20px 0 20px;
}
.echarts{
    margin-left: 100px;
    display: flex;
    position: relative;
}
.hide .el-upload--picture-card {
  display: none !important;
}
</style>
<style lang="scss" scoped>
#mychart{
  width: 800px!important;
}
.titles{
  color: #333;
  font-size: 20px;
  font-weight: 600;
}
.box{
  width: 1191px;
  //height: 475px;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  border: 1px solid #CCCCCC;
  margin: 40px 0 40px 40px;
  padding: 30px;
}
::v-deep .el-table .el-table__cell.is-center{
  color: #333;
  padding: 30px 0;
  font-weight: 600;
}
.btn1 {
  background: #d61820;
  border-radius: 8px 8px 8px 8px;
  color: #fff;
  height: 46px;
  padding: 0 24px;
}
.btn2 {
  background: #fff;
  border: 1px solid #cccccc;
  border-radius: 8px 8px 8px 8px;
  color: #666;
  height: 46px;
  padding: 0 24px;
}
.styles {
  border: none !important;
  color: #fff !important;
  background: #d61820;
}
.top-lists {
  display: flex;
  padding: 20px 0 0 0;
  .nav-view {
    cursor: pointer;
    padding: 0 26px;
    height: 44px;
    border-radius: 10px;
    border: 1px solid #999999;
    font-size: 14px;
    text-align: center;
    line-height: 44px;
    margin-right: 20px;
    color: #999;
  }
}
.width {
  position: relative;
  width: 128px;
  height: 128px;
}
.close-icon {
  position: absolute;
  right: -10px;
  top: -10px;
  width: 20px;
  height: 20px;
}
.idCardWidth {
  width: 362px !important;
  height: 211px !important;
  position: relative;
  top: 0;
}
.card {
  width: 362px !important;
  height: 211px !important;
  position: relative;
}
.card img {
  width: 362px !important;
  height: 211px !important;
}
.centers {
  display: flex;
  justify-content: center;
  align-items: center;
}
.upload-list {
  //margin-left: -20px;
}
::v-deep .el-upload {
  display: flex;
}
.upload-demo {
  display: flex;
}
.text_s {
  position: absolute;
  top: 70px;
  right: 20px;
  width: 360px;
  line-height: 20px;
  color: #666;
  font-size: 12px;
}
.bolds {
  font-weight: bold;
}
.status-size {
  margin-top: 10px;
  font-size: 20px;
  color: #000;
}
.img_cen {
  padding: 200px 0;
  margin: 0 auto;
  text-align: center;
}
.status-img img {
  width: 80px;
  height: 80px;
}
.button-s {
  width: 290px;
  height: 66px;
  background: #d61820;
  border-radius: 10px 10px 10px 10px;
  text-align: center;
  cursor: pointer;
  line-height: 66px;
  font-size: 22px;
  color: #fff;
  margin: 60px auto;
}
::v-deep .el-form-item {
  margin-bottom: 20px;
}
.titleSize {
  margin-top: 5px !important;
  color: #000 !important;
}
.idCard img {
  width: 362px;
  height: 211px;
}
.line-s {
  //padding: 0 20px;
  margin: 40px 0;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 0px 0px;
  height: 4px;
  position: absolute;
  width: 100%;
  top: 1820px;
}
.size-s {
  color: #c8161d;
  font-size: 12px;
  margin-bottom: 5px;
}
.texts {
  color: #999;
  font-size: 12px;
  margin-top: 20px;
}
.imgWidth {
  position: relative;
  width: 128px;
  height: 128px;
}
video {
  position: absolute;
  width: 128px !important;
  height: 128px !important;
  margin-top: 0 !important;
  left: 0;
  top: 0;
}
.imgWidth img {
  position: absolute;
  width: 128px !important;
  height: 128px !important;
  margin-top: 0 !important;
  left: 0;
  top: 0;
}
.box-colors {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 6px 6px 6px 6px;
  width: 128px;
  height: 128px;
  position: absolute;
}
.img-ss {
  margin-right: 20px;
}
.img-s img {
  width: 40px;
  height: 40px;
  margin-top: 25px;
}
.upload {
  width: 128px;
  height: 128px;
  background: rgba(204, 204, 204, 0.3);
  border-radius: 6px 6px 6px 6px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 20px;
  margin-right: 20px;
}
::v-deep .area .el-input__inner {
  width: 820px;
  height: 65px;
  font-size: 18px;
}
.form {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}
::v-deep .el-form--label-top .el-form-item__label {
  padding-bottom: 0;
}
::v-deep .el-form-item__label {
  font-size: 16px;
  color: #000;
}
::v-deep .centers .el-input__inner {
  text-align: center;
}
::v-deep .inputs .el-input__inner {
  width: 378px;
  height: 65px;
  font-size: 18px;
}
.flexs {
  display: flex;
  padding-right: 60px;
  background-color: #f9f9f9;
}
.banners {
  flex: 1;
  margin-top: 20px;
}
.content {
  width: 100%;
  padding: 30px 0 10px 0;
  background-color: #fff;
  margin-top: 20px;
  box-shadow: 0px 2px 20px 0px rgba(135, 135, 135, 0.3);
  border-radius: 10px 10px 10px 10px;
  .systemTitle {
    display: flex;
    justify-content: space-between;
    //margin-top: 25px;
    position: relative;
    .button {
      background-color: #ffad41;
      color: #fff;
      font-size: 16px;
      height: 48px;
      border-radius: 10px 10px 10px 10px;
      line-height: 20px;
      position: absolute;
      right: 80px;
      top: 10px;
    }
    .titles {
      margin: 25px auto auto auto;
      color: #333;
      font-size: 28px;
      font-weight: 600;
    }
  }
  .title {
    //height: 49px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    padding-bottom: 10px;
    .name {
      margin-left: 10px;
      font-weight: 600;
    }
    .line {
      width: 5px;
      height: 19px;
      background: #c8161d;
    }
  }
}
.search-sel {
  padding: 20px;
  line-height: 40px;
}
</style>
